.picture{
    display: flex;
    justify-items: center;
    align-items: center;
    margin: 3rem auto;
    background-color: var(--black);
    border-radius: 50%;
    height: 15rem;
    width: 15rem;
    flex-shrink: 0;
    overflow: hidden;
}

.pictureCircle{
    margin: auto;
    position: relative;
    border-radius: 50%;
}

.bodyRow{
    border-top: 2px solid var(--blue);
    display: flex;
    width: 100%;
    padding: 0.5rem 1.25rem;
    align-items: center;
    min-height: 3.125rem;
    color: var(--black);
}

.bodyMottoRow{
    padding: 0 0 0 1.25rem;
    display: flex;
    justify-content: space-between;
}

.backBody{
    background-color: var(--blue);
    height: 100%;
    width: 100%;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.625rem 0.625rem 0;
}

.paragraphExtend {
    font-size: 1rem;
    letter-spacing: 0.73px;
    line-height: 1.5rem;
    padding: 1.5rem 2rem;

    @media (min-width: $tabletLarge) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

.video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;
}
$spaceGap: 8;

.container {
    border: 0;
    cursor: pointer;
    color: var(--baseColor, var(--blue));
    padding: 20px 32px;
    font-size: 16px;
    display: inline-block;
    position: relative;
    text-decoration: none;
    margin: 0 1rem 2rem 0;
    box-sizing: border-box;
    width: auto;
    overflow: visible;
    line-height: 1;
    white-space: normal;
    -webkit-appearance: none;
    font-weight: 500;
    letter-spacing: 0.91px;

    &.container:before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translate($(spaceGap)px, $(spaceGap)px);
        border: 2px solid var(--baseColor, var(--white));
        font-size: 0;
        transition: transform $transition;
    }


    &.container:after {
        background: var(--white);
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 2px solid var(--baseColor, var(--white));
        font-size: 0;
        transition: transform $transition;
    }

    &.container:hover {
        &.container:after {
            transform: translate($(spaceGap)px, $(spaceGap)px);
        }

        &.container > * {
            transform: translate($(spaceGap)px, 50%);
        }
    }
}

.innerWrapper {
    position: relative;
    z-index: 2;
    transition: transform $transition;
    line-height: auto;
    display: block;
}

.link {
    --SimpleLink-opacity: 1;

    color: var(--white);
    transition: 0.3s opacity ease;
    opacity: var(--SimpleLink-opacity);

    &.link:active {
        color: var(--white);
    }

    &.link:hover {
        color: var(--white);
        text-decoration: none;
    }
}


.container {
    color: var(--white);
    font-size: 1rem;
    line-height: 1.65rem;
    word-break: break-word;

    @media (min-width: $tabletLarge) {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    letter-spacing: 1.09px;
    font-weight: 300;
    margin: 0;
}

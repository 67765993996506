.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    transform-style: preserve-3d;
    transition: $transitionCard;
    transform: rotateY(0deg);

    &.wrapper[data-flipped] {
        transform: rotateY(180deg);
    }
}

.frontWrapper {
    position: relative;
    flex: 1;
    z-index: 2;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: $transitionCard;
}

.backWrapper {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    flex-grow: 1;
    flex-shrink: 0;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transform: rotateY(180deg);
}

.backBody {
    display: flex;
}

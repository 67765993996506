.wrapper {
    background-color: var(--white);
    color: var(--black);
    border: 2px solid var(--blue);
    padding-top: 7.5rem 2rem;
    max-width: 100%;
    position: relative;
    overflow: visible;
    height: 100%;
    width: 100%;
}

.wrapperBg {
    background-color: var(--blue);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 1;
}

.content {
    background-color: var(--white);
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    border-bottom: 2px solid var(--blue);
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.headerLeft {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    letter-spacing: 0.3px;
    font-weight: 500;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.headerRight {
    border-left: 2px solid var(--blue);
    display: flex;
    align-items: center;
    justify-items: center;
    width: 4.5rem;
    height: 100%;

    &.headerRight > * {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
}

.body {
    background-color: var(--white);
    height: 100%;
}

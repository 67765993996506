
.flipButton {
    background-color: var(--cyan);
    width: 4.5rem;
    height: 100%;
    padding: 0.625rem;;
    transition: 0.3s opacity ease;
    position: relative;
    z-index: 2;

    &.flipButton:hover {
        opacity: 0.3;
    }

    &.back:hover {
        opacity: 0.7;
    }

}
